<template>
    <div>
        ola k ase desde reportes
        <transition
            name="fade"
            mode="out-in"
        >
            <router-view/>
        </transition>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>